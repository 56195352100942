// var cookies pop up 
const cookiesbox = document.querySelector('.cookies')
const cookiesbtn = document.querySelector('.cookies__btn')

// var recomendation
const rec_img = document.querySelectorAll('.recomendation__box--item-img')
let imgSrc;
// var nav
const nav = document.querySelector('.nav')
const navBtn = document.querySelector('.nav__btn')
const navBtnClose = document.querySelector('.nav__btn--close')
const navMob = document.querySelector('.nav__item')
const navlinks = document.querySelectorAll('.nav__item--a')

// p for year
const footerYear = document.querySelector('.footer__year')

// section for gsap
const section = document.querySelectorAll('section')

const handlerCurrentYear = () => {
	const year = (new Date).getFullYear()
	footerYear.innerText = year
}
// ----------- cookies handler --------------

const cookiesHandler = () => {
	cookiesbox.classList.add('hide')
	sessionStorage.setItem('cookie', 'true')
}
  
window.onload = function() {
    if(sessionStorage.getItem('cookie')) {
         cookiesbox.classList.add('hide')
		// window.removeEventListener('scroll', noScroll)
    } 
}

cookiesbtn.addEventListener('click',cookiesHandler )


//--------------------- nav ----------------------------

// window.onscroll = () => {
// 	section.forEach(sec => {

// 		let top = window.scrollY;
// 		let offset = sec.offsetTop - 150;
// 		let height = sec.offsetHeight ;
// 		let id = sec.getAttribute('id')

// 		if( top >= offset && top < offset + height || id != '#home') {
// 			navlinks.forEach(links => {
// 				links.classList.remove('active')
// 				document.querySelector('.nav__item--a[href*=' + id + ']').classList.add('active')
// 			} )
// 		}
// 	})

// }

const navHandler = () => {
	navMob.classList.toggle('navShow')
}

navBtn.addEventListener('click',navHandler)
navBtnClose.addEventListener('click',navHandler)
navlinks.forEach(link => link.addEventListener('click',navHandler))



function addShadow() {
if (window.scrollY > 0 ) {
	nav.classList.add('shadow')
	cookiesbox.classList.add('shadow')
} else {
	nav.classList.remove('shadow')
	cookiesbox.classList.remove('shadow')
}
}

window.addEventListener('scroll',addShadow)


//------------Recomendation ----------------------------

// function resize(e) {
// 	rec_img.forEach(img => e.target.classList.toggle('img__zoom'))
// }

// rec_img.forEach(img => img.addEventListener('click',resize))
// const modal = document.querySelector('.recomendation__modal')

// rec_img.forEach(img => {img.addEventListener('click', (e) => {
// 	imgSrc=e.target.src;
// 	//console.log(imgSrc);
// 	})
// })

// const handleModal = () => {
// 	if(modal.classList.contains('visible')){
// 			//modal.classList.remove('visible')
// 		//newImage.removeAttribute('src',imgSrc)
// 		//modal.remove()
// 		imgSrc = ""
// 		//newImage.classList.remove('modal__img')
// 		//modal.remove(newImage)
// 		//document.remove(modal)
// 		modal.remove()
// 		modal.classList.remove('visible')
// 		console.log("cyce");
// 	} else {
// 		const newImage = document.createElement('img')
	
// 		newImage.setAttribute('src',imgSrc)
// 		newImage.classList.add('modal__img')
// 		modal.append(newImage)
// 		modal.classList.add('visible')
// 	}
// }

// const handleModal = () => {
// 	if(modal.classList.contains('visible')){
// 			//modal.classList.remove('visible')
// 		//newImage.removeAttribute('src',imgSrc)
// 		//modal.remove()
// 		imgSrc = ""
// 		//newImage.classList.remove('modal__img')
// 		//modal.remove(newImage)
// 		//document.remove(modal)
// 		modal.remove()
// 		modal.classList.remove('visible')
// 		console.log("cyce");
// 	} 
// 	x {
// 		const newImage = document.createElement('img')
	
// 		newImage.setAttribute('src',imgSrc)
// 		newImage.classList.add('modal__img')
// 		modal.append(newImage)
// 		modal.classList.add('visible')
// 	}
// }
rec_img.forEach(img => {img.addEventListener('click', (e) => {
imgSrc=e.target.src;
})
})
const modal = document.querySelector('.recomendation__modal')
let newImage = ""
const openModal = () =>{
	newImage = document.createElement('img')

	newImage.setAttribute('src',imgSrc)
	newImage.classList.add('modal__img')
	modal.append(newImage)
	modal.classList.add('visible')
}
const removeModal = () =>{
	newImage.remove()
	modal.classList.toggle('visible')
}


rec_img.forEach(img => img.addEventListener('click',openModal))
modal.addEventListener('click',removeModal)

handlerCurrentYear()

// let imgModal = (src) => {
// 	const modal = document.createElement('div');
// 	modal.setAttribute('class','modal')
// 	console.log(modal);

// 	document.querySelector('main').append(modal)
// 	const newImage = document.createElement('img')
	
// 	newImage.setAttribute('src',src)
// 	modal.append(newImage)
// }

//------------END  Recomendation ----------------------------

////gsap

// const sections = document.querySelectorAll('section')

// sections.forEach(section => {
// 	gsap.fromTo(section.children, {y: '+=100', opacity:0}, 
// 	{y: 0, opacity: 1, stagger: 0.2, duration: 1,ease:
// 	'easeInOut', 
// 		scrollTrigger: {
// 			trigger: section,
// 			start: 'top 90%',
// 			//scrub: 1,
// 	}})

// })
